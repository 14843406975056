import React, { FormEvent, useEffect, useState } from 'react';
import { Button as Btn } from '@app/shadcn/components/ui/button';
import Logo from '@app/components/icons/logo';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import BackIcon from '@app/components/icons/back';
import Button from '@app/components/common/button';
import { useGetUserStatusQuery, useGoogleLoginQuery, useSendOtpMutation, useVerifyOtpMutation } from '@app/store/users/api';
import TextInput from '@app/components/common/input/TextInput';
import { isEmptyString } from '@app/utils/stringUtils';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [stage, setStage] = useState(0);
    const router = useRouter();

    const { data: googleLoginData } = useGoogleLoginQuery();
    const googleOAuthUrl = googleLoginData?.auth_url;

    const [sendOtp, { isLoading: isSending, isSuccess: isSendSuccess, isError: isSendError }] = useSendOtpMutation();
    const [verifyOtp, { isLoading: isVerifying }] = useVerifyOtpMutation();

    const { data: user, isSuccess: isUserStatusSuccess } = useGetUserStatusQuery();

    useEffect(() => {
        if (isUserStatusSuccess && !user?.id) {
            router.push('/login');
        } else if (user?.id) {
            router.push('/dashboard');
        }
    }, [user, isUserStatusSuccess]);

    const handleSendCode = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            await sendOtp({ email }).unwrap();
            setStage(1);
            toast.success('OTP sent successfully!');
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error('Failed to send OTP. Please try again.');
        }
    };

    const handleVerifyOtp = async () => {
        try {
            await verifyOtp({ email, otp_token: otp }).unwrap();
            toast.success('OTP verified successfully!');
            router.push('/dashboard');
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error('Invalid OTP. Please try again.');
        }
    };

    const handleBack = () => {
        setStage(0);
        setOtp('');
    };

    const handleLogin = () => {
        if (googleOAuthUrl) {
            window.location.href = googleOAuthUrl;
        }
    };
    const handleLogoCLick = () => {
        router.push('/');
    };

    return (
        <div className="my-auto w-full">
            <div className="grid h-screen w-full grid-cols-1 lg:grid-cols-2">
                {/* Gradient Side (Shown on Left on Larger Screens, Below on Mobile) */}
                <div className="hidden h-full justify-center bg-gradient-to-r from-pink-400 to-yellow-400 p-6 md:p-10 lg:flex lg:p-16">
                    <div className="my-auto flex flex-col items-center justify-center text-center" style={{ maxWidth: '495px', height: '96px' }}>
                        <h1 className="text-2xl font-semibold text-white md:text-3xl">Customize Your Form URLs for</h1>
                        <h1 className="text-2xl font-semibold text-white md:text-3xl">a Seamless Experience</h1>
                        <p className="mt-4 text-sm font-normal text-white md:text-base" style={{ maxWidth: '360px', height: '48px' }}>
                            Take control of your form links by creating custom URLs that align with your brand.
                        </p>
                    </div>
                </div>

                {/* Right Side with Form */}
                <div className="relative flex h-full justify-center bg-white p-6 md:p-10 lg:p-16">
                    <div className="my-auto w-full pr-6 md:pr-20">
                        <div onClick={handleLogoCLick} className="absolute left-6 top-4 cursor-pointer md:left-20 md:top-10">
                            <Logo className="h-[19.75px] w-[103.82px]" />
                        </div>

                        {stage === 1 && (
                            <button onClick={handleBack} className="md:top-17 absolute top-20 mb-5 flex items-center text-sm text-[#858585]">
                                <BackIcon className="mr-1" />
                                Back
                            </button>
                        )}

                        {stage === 0 ? (
                            <>
                                <div className="mb-10">
                                    <h2 className="mt-8 text-xl font-semibold text-gray-800 md:text-2xl">Welcome Back!</h2>
                                    <h3 className="mb-6 text-sm text-gray-900 md:mb-16">Please sign in to continue</h3>
                                </div>

                                <Btn variant="outline" className="flex h-[45px] w-full items-center justify-center rounded-md text-neutral-800 md:w-[540px]" style={{ backgroundColor: '#F6F6F6' }} onClick={handleLogin}>
                                    <div className="flex items-center">
                                        <Image src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" alt="Google" className="mr-2 h-6 w-6" width={24} height={24} />
                                        Sign in with Google
                                    </div>
                                </Btn>

                                <div className="relative mt-12 py-4">
                                    <div className="flex items-center justify-center md:w-[540px]">
                                        <div className="w-full border-b border-gray-300"></div>
                                        <span className="mx-4 bg-white px-4 text-sm text-gray-500">Continue</span>
                                        <div className="w-full border-b border-gray-300"></div>
                                    </div>
                                </div>
                                <form onSubmit={handleSendCode}>
                                    <label htmlFor="email" className="mt-12 block font-medium text-neutral-800">
                                        Email
                                    </label>
                                    <TextInput id="email" type="email" placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} className="mt-2 w-full rounded-[8px] border md:w-[540px]" />
                                    <Button className="mt-12 h-[48px] w-full rounded-md bg-blue-600 shadow-md transition-shadow hover:shadow-lg md:w-[540px]" disabled={isSending || isEmptyString(email)}>
                                        {isSending ? 'Sending...' : 'Send Code'}
                                    </Button>
                                </form>

                                {isSendSuccess && <p className="mt-2 text-green-500">OTP sent successfully!</p>}
                                {isSendError && <p className="mt-2 text-red-500">Failed to send OTP. Please try again.</p>}
                            </>
                        ) : (
                            <>
                                <div className="mb-10 mt-16">
                                    <h2 className="mt-19 text-xl font-semibold text-gray-800 md:text-2xl">Verification</h2>
                                    <h3 className="mb-6 text-sm text-gray-900 md:mb-16">
                                        A verification code has been sent to <span style={{ color: '#FF9B0A' }}>{email}</span>
                                    </h3>
                                </div>

                                <div className="space-y-6 md:space-y-8">
                                    <label htmlFor="otp" className="block font-medium text-neutral-800">
                                        Verification Code
                                    </label>
                                    <TextInput id="otp" type="text" placeholder="Enter the verification code" value={otp} onChange={(e) => setOtp(e.target.value)} className="w-full rounded-[8px] border md:w-[540px]" />

                                    <Button className="mt-4 h-[48px] w-full rounded-md bg-blue-600 text-white shadow-md transition-shadow hover:bg-blue-700 hover:shadow-lg md:w-[540px]" onClick={handleVerifyOtp} disabled={isVerifying}>
                                        {isVerifying ? 'Verifying...' : 'Verify Code'}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {/* Gradient Section Below Login Form for Small Screens */}
                <div className="flex h-full justify-center bg-gradient-to-r from-pink-400 to-yellow-400 p-6 lg:hidden">
                    <div className="my-auto flex flex-col items-center justify-center text-center" style={{ maxWidth: '495px' }}>
                        <h1 className="text-2xl font-semibold text-white">Customize Your Form URLs for</h1>
                        <h1 className="text-2xl font-semibold text-white">a Seamless Experience</h1>
                        <p className="mt-4 text-sm font-normal text-white" style={{ maxWidth: '360px' }}>
                            Take control of your form links by creating custom URLs that align with your brand.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
