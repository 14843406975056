import React from 'react';

function BackIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18.75L9 12.75L15 6.75" stroke="#858585" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}

export default BackIcon;
